import { Controller } from 'stimulus';

export default class extends Controller {
  static values = {
    clickToCollapseSelector: String
  }

  connect() {
    this.clickToCollapseTarget = document.querySelector(this.clickToCollapseSelectorValue)
    this.caretIcon = this.clickToCollapseTarget.querySelector('i')
    this.initClickEvents()
  }

  initClickEvents() {
    this.clickToCollapseTarget.addEventListener('click', this.handleCollapseState.bind(this))
    this.clickToCollapseTarget.addEventListener('collapse', this.handleCollapseState.bind(this))
  }

  handleCollapseState(ev) {
    let isExpanded =
      this.caretIcon.classList.contains('fa-caret-up') &&
      !this.caretIcon.classList.contains('fa-caret-down')

    if (this.caretIcon.classList.contains('disabled') || this.caretIcon.closest('.disabled')) {
      isExpanded = true
    }

    this.caretIcon.classList.toggle('fa-caret-up', !isExpanded)
    this.caretIcon.classList.toggle('fa-caret-down', isExpanded)
    this.element.classList.toggle('hidden', isExpanded)
  }
}
