import { Controller } from 'stimulus';
import visibilityHandler from './visibility_handler';

const RELATIONSHIP_TYPE_OTHER = 'Other';
const DEFAULT_CONTEXT = '.grid-layout';

export default class extends Controller {
  connect() {
    const componentContainer = this.element;
    const context = this.element.closest(this.element.dataset['context'] || DEFAULT_CONTEXT)
    const relationshipSelector = this.element.dataset['relationship_selector']

    context.querySelector(relationshipSelector).addEventListener('change', (ev) => {
      if (ev.currentTarget.value == RELATIONSHIP_TYPE_OTHER) {
        visibilityHandler.show(componentContainer);
      } else {
        visibilityHandler.hide(componentContainer);
      }
    })
  }
}
