import { Controller } from 'stimulus';

export default class extends Controller {
  static targets = [ 'deathLocation', 'deathLocationInfoIcon', 'remainsLocation', 'remainsLocationInfoIcon', 'mirrorCheck' ]

  connect() {
    this.setRemainsBasedOnMirrorCheck();
    this.onDeathLocationChange();
    this.onRemainsLocationChange();
  }

  onDeathLocationChange() {
    if (!this.hasDeathLocationTarget) return;
    this.toggleInfoIcon(this.deathLocationTarget, this.deathLocationInfoIconTarget);
    this.toggleExtraFields(this.deathLocationTarget, 'data-toggle-by-death-location');
    this.handleRemainsLocationForMissingOption();
  }

  onRemainsLocationChange() {
    if (!this.hasRemainsLocationTarget) return;
    this.toggleInfoIcon(this.remainsLocationTarget, this.remainsLocationInfoIconTarget);
    this.toggleExtraFields(this.remainsLocationTarget, 'data-toggle-by-remains-location');

    if (this.hasMirrorCheckTarget) {
      this.mirrorCheckTarget.value = (this.remainsLocationTarget.value == 'Same as Location of Death');
    }
  }

  setRemainsBasedOnMirrorCheck() {
    if (this.hasMirrorCheckTarget && (this.mirrorCheckTarget.value === 'true' || this.mirrorCheckTarget.value === 't')) {
      this.remainsLocationTarget.value = 'Same as Location of Death';
    }
  }

  handleRemainsLocationForMissingOption() {
    if (this.deathLocationTarget.value === 'In Transport') {
      $('.location-of-remains-select option[value="Same as Location of Death"]').remove();
      this.onRemainsLocationChange();
    } else {
      if ($('.location-of-remains-select option[value="Same as Location of Death"]').length == 0)
        $('.location-of-remains-select').prepend('<option value="Same as Location of Death">Same as Location of Death</option>')
    }
  }

  toggleInfoIcon(selectedOption, infoIcon) {
    if (selectedOption.value === "Decedent's Residence") {
      infoIcon.style.display = 'block';
    } else {
      infoIcon.style.display = 'none';
    }
  }

  toggleExtraFields(selectedOption, selector) {
    const selectedValue = selectedOption.value;
    document.querySelectorAll("[" + selector + "]").forEach((el) => {
      el.classList.add('hidden');
      el.querySelectorAll('input').forEach((input) => input.disabled = true);
    });

    document.querySelectorAll("[" + selector + "*=\"" + selectedValue + "\"]").forEach((el) => {
      el.classList.remove('hidden');
      el.querySelectorAll('input').forEach((input) => input.disabled = false);
    });
  }
}
