export const requireField = (field, requiredCondition) => {
  const fieldContainer = field.closest('.form-group')
  
  if (!fieldContainer.querySelector('label abbr[title=required]')) {
    const abbr = '<abbr title="required">*</abbr>'
    const labelText = fieldContainer.querySelector('label').innerText

    console.warn(`Missing nested ${abbr} for label: ${labelText}`, fieldContainer)
  }

  fieldContainer.classList.toggle('display-required', requiredCondition)
}

export const requireFields = (fields, requiredCondition) => {
  Array.prototype.forEach.call(fields, (field) => requireField(field, requiredCondition))
}
