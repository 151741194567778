import { Controller } from 'stimulus';

export default class extends Controller {
  static targets = ['deathCauseNames', 'causeOfDeathName', 'timePeriod', 'deathCauseNamesTags', 'addButton']

  connect() {
    const deathCauseNames = this.deathCauseNamesTarget.value.split(',');

    this.updateDeathCauseNamesTagsUI(deathCauseNames);
    this.handleAddButtonState();
    this.initDeathCauseSelect2();
  }

  handleAddButtonState() {
    this.addButtonTarget.classList.toggle('disabled', this.causeOfDeathNameTarget.value.length < 1);
  }

  handleRemoveTagState(hidden) {
    this.removeTagElement().classList.toggle('hidden', hidden)
  }

  removeTagElement() {
    return this.element.querySelector('.death-cause-name-inputs .close-select2')
  }

  removeCauseOfDeath(ev) {
    const valueToRemove = ev.currentTarget.dataset['tag_value'];
    const deathCauseNames = this.deathCauseNamesTarget.value.split(',');
    const indexToRemove = deathCauseNames.indexOf(valueToRemove);
    
    if (indexToRemove != -1) {
      deathCauseNames.splice(indexToRemove, 1);
    }

    this.updateDeathCauseNamesTagsUI(deathCauseNames);
  }

  addCauseOfDeath(ev) {
    ev.preventDefault();
    ev.stopPropagation();

    if (this.addButtonTarget.classList.contains('disabled')) return;

    const valueToAdd = [
      this.causeOfDeathNameTarget.value.trim().replaceAll('|', '-'),
      this.timePeriodTarget.value.trim()
    ].filter(value => value.length > 0).join(' | ');

    const deathCauseNames = this.deathCauseNamesTarget.value.split(',');

    if (deathCauseNames.indexOf(valueToAdd) == -1) {
      deathCauseNames.push(valueToAdd);
    }

    this.causeOfDeathNameTarget.value = '';

    if (this.timePeriodTarget == document.activeElement) {
      this.timePeriodTarget.blur();
    }

    this.timePeriodTarget.value = '';
    this.clearCauseOfDeathNameSelect2();
    this.handleAddButtonState();

    this.updateDeathCauseNamesTagsUI(deathCauseNames);
  }

  updateDeathCauseNamesTagsUI(deathCauseNames) {
    const tagsToUpdate = deathCauseNames.map(causeName =>
      causeName?.trim() && `<span class="tags">${causeName} ${this.tagRemoveIconHTML(causeName)}</span>`
    ).join('')

    this.deathCauseNamesTarget.value = deathCauseNames.join(',');
    this.deathCauseNamesTagsTarget.innerHTML = tagsToUpdate;
  }

  tagRemoveIconHTML(deathCauseName) {
    if (this.element.closest('[data-read-only="true"]')) return ''

    return `<i
              class="fa fa-times"
              data-tag_value="${deathCauseName}"
              data-action="click->vitals--death-causes#removeCauseOfDeath"
            ></i>`;
  }

  initDeathCauseSelect2() {
    const sourceURL = this.causeOfDeathNameTarget.dataset['source'];

    $(this.causeOfDeathNameTarget).select2({
      placeholder: '',
      allowClear: true,
      ajax: {
        url: sourceURL,
        dataType: 'json',
        data: (term, page) => {
          this.causeOfDeathNameTarget.dataset['term'] = term;

          return { q: term };
        },
        results: (data, page) => {
          const term = this.causeOfDeathNameTarget.dataset['term'];

          if (term.length > 0) {
            const newTerm = { id: term, text: term };
            if (data.none(item => item.text == term)) data.unshift(newTerm);
          }

          return { results: data }
        }
      }
    });

    $(this.causeOfDeathNameTarget).on('select2-close select2-removed', (ev) => {
      this.handleAddButtonState();
      this.handleRemoveTagState(this.causeOfDeathNameTarget.value.length < 1);
    });

    $(this.causeOfDeathNameTarget).on('select2-opening', (ev) => {
      this.handleRemoveTagState(true);
    });

    this.removeTagElement().addEventListener('click', (ev) => {
      this.clearCauseOfDeathNameSelect2();
      this.handleAddButtonState();
    });
  }

  clearCauseOfDeathNameSelect2() {
    $(this.causeOfDeathNameTarget).select2('val', '');
    this.handleRemoveTagState(true);
  }
}
