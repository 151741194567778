import { Controller } from 'stimulus';

export default class extends Controller {
  static targets = ['sourceType', 'sourceTypeSection', 'contactType', 'contactDetails', 'primaryPhone']

  connect() {
    const selectedValue = this.sourceTypeTarget.selectedOptions[0].value
    this.handleSourceTypeSectionVisibility(selectedValue)

    const contactTypeOption = this.contactTypeTarget.selectedOptions[0]
    this.handleContactDetailsVisibility(contactTypeOption)

    $(this.sourceTypeTarget).closest('form').trigger('reinitialize.areYouSure')
  }

  triggerLocationChange(ev) {
    const selectedValue = ev.currentTarget.selectedOptions[0].value
    this.handleSourceTypeSectionVisibility(selectedValue)
  }

  triggerContactTypeChange(ev) {
    const selectedOption = ev.currentTarget.selectedOptions[0]
    this.handleContactDetailsVisibility(selectedOption)
  }
  
  handleSourceTypeSectionVisibility(selectedValue) {    
    this.sourceTypeSectionTargets.forEach((sourceTypeSection) => {      
      if (sourceTypeSection.getAttribute('data-value') == selectedValue) {
        if (selectedValue === 'callers_residence') {
          // Caller's residence fields should stay disabled
          this.showSection(sourceTypeSection)
        } else {
          this.showAndEnableSection(sourceTypeSection)
          // for visibility fix (disable field if none address identifier is choosen)
          var identifier_options = this.element.querySelector('.identifier-options input[type=radio]')
          if (!!identifier_options) {
            identifier_options.dispatchEvent(new Event('change'))
          }
        }
      } else {
        this.hideAndDisableSection(sourceTypeSection)
      }
    }, this)
  }

  handleContactDetailsVisibility(option) {
    this.populateContactDetails(option)

    if (option.value) {
      this.hideSection(this.contactDetailsTarget)
    } else {
      this.showSection(this.contactDetailsTarget)
    }
  }

  enableSection(target) {
    // enable inner inputs
    target.querySelectorAll('[name]').forEach((input) => {
      input.disabled = false
    })
  }

  disableSection(target) {
    // disable inner inputs
    target.querySelectorAll('[name]').forEach((input) => {
      input.disabled = true
    })
  }

  showSection(target) {
    target.style.display = 'block';
  }

  showAndEnableSection(target) {
    this.showSection(target)
    this.enableSection(target)
  }

  hideSection(target) {
    target.style.display = 'none';
  }

  hideAndDisableSection(target) {
    this.hideSection(target)
    this.disableSection(target)
  }

  populateContactDetails(option) {
    const residenceOwnerId = option.value
    this.element.querySelector('input[name*="[residence_owner_attributes][id]"]').value = residenceOwnerId
    this.element.querySelector('input[name*="[residence_owner_attributes][first_name]"]').value = option.getAttribute('data-first-name')
    this.element.querySelector('input[name*="[residence_owner_attributes][last_name]"]').value = option.getAttribute('data-last-name')
    this.element.querySelector('select[name*="[residence_owner_attributes][relationship]"]').value = option.getAttribute('data-relationship') || ''
    this.element.querySelector('input[name*="[residence_owner_attributes][relationship_type_attributes][name]"]').value = option.getAttribute('data-relationship-details')
    this.element.querySelector('input[name*="[residence_owner_attributes][contact_information_attributes][id]"]').value = option.getAttribute('data-contact-information-id')
    this.element.querySelector('input[name*="[residence_owner_attributes][contact_information_attributes][phone_numbers_attributes][0][id]"]').value = option.getAttribute('data-primary-phone-id')
    // populate primary phone number with the value from Caller section if acquaintances are the same
    const caseCallerAcquaintanceId = $('#case_case_caller_attributes_acquaintance_attributes_id').val()
    const primaryPhone = residenceOwnerId === caseCallerAcquaintanceId ? $('.js-caller-primary-phone').val() : option.getAttribute('data-primary-phone-value')
    this.element.querySelector('input[name*="[residence_owner_attributes][contact_information_attributes][phone_numbers_attributes][0][value]"]').value = primaryPhone
    const phoneType = option.getAttribute('data-primary-phone-type') || 'Home'
    this.element.querySelector(`input[name*='[residence_owner_attributes][contact_information_attributes][phone_numbers_attributes][0][phone_type]'][value='${phoneType}']`).checked = true
  }

  triggerPrimaryPhoneChange() {
    this.syncPrimaryPhone()
  }

  syncPrimaryPhone() {
    // sync primary phone number in the Caller section if acquaintances are the same
    const caseCallerAcquaintanceId = $('#case_case_caller_attributes_acquaintance_attributes_id').val()
    const residenceOwnerId = $('.js-residence-owner').val()

    if (caseCallerAcquaintanceId === residenceOwnerId) {
      const phone_value = this.primaryPhoneTarget.value
      const $phone_input = $('.js-caller-primary-phone')

      $phone_input.val(phone_value)
    }
  }
}
