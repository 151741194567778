import { Controller } from 'stimulus';
import visibilityHandler from '../shared/visibility_handler';

const COUNTRY_ADDRESS_FIELDS = {
  US:    ['address1', 'address2', 'city', 'identifier', 'identifier_description', 'us_state', 'zip', 'country', 'borough', 'county', 'inside_city_limits'],
  CA:    ['address1', 'address2', 'city', 'identifier', 'identifier_description', 'province', 'postal_code', 'country', 'inside_city_limits'],
  MX:    ['address1', 'address2', 'city', 'identifier', 'identifier_description', 'mx_state', 'postal_code', 'country', 'inside_city_limits'],
  OTHER: ['address1', 'address2', 'address3', 'address4', 'country', 'inside_city_limits']
}

// container to be hidden or shown according to its fields
const FIELDS_CONTAINERS_SELECTORS = ['.identifier-container', '.state-container', '.zip-container']
const STATES_WITH_BOROUGH = ['AK', 'CT', 'NJ', 'NY', 'PA', 'VA']

export default class extends Controller {
  static targets = ['country', 'identifier', 'identifierDescription', 'state']

  connect() {
    this.handleAddressFieldsVisibility()
    this.setIdentifierFieldsVisibility()
    $(this.element).initialize_zip_search()
    this.onStateChange()
  }

  onCountryChange(_ev) {
    this.handleAddressFieldsVisibility();
  }

  onIdentifierChange(_ev) {
    this.setIdentifierFieldsVisibility()
  }

  onStateChange(ev) {
    if (!this.hasStateTarget) return;

    const stateValue = ev?.target?.value || this.stateTargets.find((stateEl) => !stateEl.closest('.hidden'))?.value;
    const boroughContainer = this.element.querySelector('.address-borough.state-container');

    if (STATES_WITH_BOROUGH.includes(stateValue)) {
      visibilityHandler.show(boroughContainer, boroughContainer?.getAttribute('defaultDisabled') != 'true');
    } else {
      visibilityHandler.hide(boroughContainer, boroughContainer?.getAttribute('defaultDisabled') != 'true');
    }
  }

  handleAddressFieldsVisibility() {
    const selectedValue = this.countryTarget.selectedOptions[0].value;
    const countrySelected = Object.keys(COUNTRY_ADDRESS_FIELDS).includes(selectedValue) ? selectedValue : 'OTHER';
    const addressFieldNames = COUNTRY_ADDRESS_FIELDS[countrySelected];
    const addresssInputFields = this.element.querySelectorAll('[name*=address_attributes]');

    this.setContainerCountrySelected(countrySelected)

    addresssInputFields.forEach(input => {
      // expect the input to have a wrapper with a class form-group
      const inputContainer = input.closest('.form-group')
      const attributeName = this.extractAttributeName(input.name)

      if (addressFieldNames.includes(attributeName)) {
        visibilityHandler.show(inputContainer, inputContainer.getAttribute('defaultDisabled') != 'true')
      } else {
        visibilityHandler.hide(inputContainer, inputContainer.getAttribute('defaultDisabled') != 'true')
      }

      if (countrySelected === 'CA' && attributeName === 'postal_code') {
        input.classList.add('ca-postal-code');
        input.setAttribute('maxlength', '7');
      } else {
        input.classList.remove('ca-postal-code');
        input.removeAttribute('maxlength');
      }
    })

    this.handleFieldContainerVisibility()
    this.setIdentifierFieldsVisibility()
  }

  setIdentifierFieldsVisibility() {
    if (!this.hasIdentifierTarget) return;

    const selectedIdentifier = this.identifierTargets.filter(input => input.checked || input.getAttribute('checked') == 'checked')[0] || {};

    if (!this.hasIdentifierDescriptionTarget) return;
    const inputContainer = this.identifierDescriptionTarget.closest('.form-group')

    if (selectedIdentifier.value && inputContainer.getAttribute('defaultDisabled') != 'true') {
      visibilityHandler.enableInnerFields(inputContainer)
    } else {
      visibilityHandler.disableInnerFields(inputContainer)
    }
  }

  extractAttributeName(name) {
    return name.split(/\[|\]/).filter((splittedName) => splittedName != '').last()
  }

  handleFieldContainerVisibility() {
    this.element.querySelectorAll(FIELDS_CONTAINERS_SELECTORS.join(', ')).forEach(container => {
      if (container.querySelectorAll('.form-group:not(.hidden)').length > 0 || container.matches('.form-group:not(.hidden)')) {
        visibilityHandler.show(container, false)
      } else {
        visibilityHandler.hide(container, false)
      }
    })
  }

  setContainerCountrySelected(countrySelected) {
    Object.keys(COUNTRY_ADDRESS_FIELDS).forEach((country) => {
      this.element.classList.remove(`country-selected-${country.toLowerCase()}`)
    })

    this.element.classList.add(`country-selected-${countrySelected.toLowerCase()}`)
  }
}
