const enableInnerFields = (target) => {
  const isReadOnly = target?.closest('[data-read-only="true"]')
  target?.querySelectorAll('[name]').forEach((input) => {
    input.disabled = isReadOnly || input.classList.contains('pb-disabled')
  })
}

const disableInnerFields = (target) => {
  target?.querySelectorAll('[name]').forEach((input) => {
    input.disabled = true
  })
}

export default {
  enableInnerFields,
  disableInnerFields,
  show(target, enableFields = true) {
    if (target && target.getAttribute('defaultHidden') != 'true') {
      target.classList.remove('hidden');
    }

    if (enableFields) enableInnerFields(target);
  },
  hide(target, disableFields = true) {
    target?.classList.add('hidden')
    if (disableFields) disableInnerFields(target)
  },
}
