import {Controller} from "stimulus";

export default class extends Controller {
  static targets = ["availableOrganizations", "addedOrganizations",
    "clearAvailable", "inputAvailable", "clearAdded", "inputAdded"];

  filterAvailable(event) {
    const searchedValue = event.target.value.toLowerCase();
    this.availableOrgs = this.availableOrganizationsTarget.querySelectorAll('.selectable-item')

    if (searchedValue.length > 2) {
      this.clearAvailableTarget.style.display = "";
      this.availableOrganizationsTarget.scrollTop = 0;

      this.search(searchedValue, this.availableOrgs);
    } else {
      this.reset(this.clearAvailableTarget, this.availableOrgs);
    }
  }

  filterAdded(event) {
    const searchedValue = event.target.value.toLowerCase();
    this.addedOrgs = this.addedOrganizationsTarget.querySelectorAll('.selectable-item')

    if (searchedValue.length > 2) {
      this.clearAddedTarget.style.display = "";
      this.addedOrganizationsTarget.scrollTop = 0;

      this.search(searchedValue, this.addedOrgs)
    } else {
      this.reset(this.clearAddedTarget, this.addedOrgs);
    }
  }

  search = (searchedValue, orgs) => {
    Array.from(orgs).forEach(org => {
      let name = this.getOrganizationName(org)

      if (name.includes(searchedValue) && org.classList.contains("parent-org")) {
        org.classList.add("org-match-query");
      }

      if (name.includes(searchedValue) || $(org).parents(".org-match-query").length > 0) {
        $(org).parents(".parent-org").show();
        org.style.display = "";
      } else {
        org.style.display = "none";
      }
    });
  }

  getOrganizationName(org) {
    if (org.classList.contains("parent-org")) {
      return org.childNodes[0].data.toLowerCase();
    } else {
      return org.textContent.toLowerCase();
    }
  }

  reset(clearTarget, orgs) {
    clearTarget.style.display = "none";
    Array.from(orgs).forEach(org => {
      org.style.display = "";
      org.classList.remove("org-match-query");
    });
  }

  getContextElement(context, event) {
    return context[event.target.dataset.searchTarget];
  }

  clearSearch(event) {
    const id = event.target.parentElement.id;
    if (id === 'available') {
      this.clearAndResetInput(this.inputAvailableTarget, this.clearAvailableTarget, this.availableOrgs);
    } else if (id === 'added') {
      this.clearAndResetInput(this.inputAddedTarget, this.clearAddedTarget, this.addedOrgs);
    }
  }

  clearAndResetInput(inputTarget, clearTarget, orgs) {
    inputTarget.value = '';
    inputTarget.focus();
    this.reset(clearTarget, orgs);
  }
}
