import { Controller } from 'stimulus';

export default class extends Controller {

  static targets = ['indigenousStatus', 'nameOfFirstNation']

  connect() {
    this.onIndigenousStatusChange();
  }

  onIndigenousStatusChange() {
    this.setNameOfFirstNationVisibility();
  }

  setNameOfFirstNationVisibility()
  {
    if (!this.hasIndigenousStatusTarget) return;
    const indigenousStatus = this.indigenousStatusTarget.checked;
    const label = this.nameOfFirstNationTarget.previousElementSibling;
    
    if (indigenousStatus) {
        this.nameOfFirstNationTarget.style.display = 'block';
        label.style.display = 'block';
      } else {
        this.nameOfFirstNationTarget.style.display = 'none';
        label.style.display = 'none';
        this.clearNameOfFirstNationTarget();
    }
  }

  clearNameOfFirstNationTarget()
  {
    this.nameOfFirstNationTarget.value = '';
  }
}