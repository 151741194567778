import { Controller } from 'stimulus';
import visibilityHandler from '../shared/visibility_handler';
import { requireField, requireFields } from '../shared/form_utils';

const ACQUAINTANCE_FAMILY_FIELDS_SELECTORS = [
  '.case_case_caller_first_name',  '.case_case_caller_last_name',  '.case_case_caller_email',
  '.case_case_caller_primary_phone', '.case_case_caller_acquaintance_should_send_collaboration_invitation', '.address-component-container',
  '.pc-invite-container'
]

const MEDICAL_FIELDS_SELECTORS = ['.caller-rolodex']

export default class extends Controller {
  static targets = ['firstName', 'lastName', 'relationship', 'primaryPhone', 'email', 'planningCenterInvite', 'rolodex', 'planningCenterTokenField']
  static classes = ['required']
  static values = {
    familyRelationshipList: Array,
    medicalRelationshipList: Array,
    visibleFields: Array
  }

  connect() {
    this.handleFieldsByRelationship()
    this.validateForm()
    this.show_or_hide_preview_cogs()
    this.bind_close_popover_preview_listener()
    this.bind_resend_popover_preview_listener()
    this.bind_resend_message()
  }

  onRelationshipChange() {
    this.handleFieldsByRelationship()
    this.validateForm()
  }

  triggerInputKeyUp() {
    this.validateForm()
  }

  triggerPrimaryPhoneChange() {
    this.syncPrimaryPhone()
  }

  triggerPrimaryPhoneKeyUp() {
    this.validateForm()
    this.show_or_hide_preview_cogs()
    $('.js-primary-phone-error').remove()
  }

  triggerEmailKeyUp() {
    this.validateForm()
    this.show_or_hide_preview_cogs()
    $('.js-email-error').remove()
  }

  show_or_hide_preview_cogs() {
    const container = '.collaboration_invitation_container'
    var phone = this.hasPrimaryPhoneTarget ? this.primaryPhoneTarget.value : ''
    var email = this.hasEmailTarget ? this.emailTarget.value : ''

    var phoneTooltip = $(container).find('.fa-cog-only-phone')
    var emailTooltip = $(container).find('.fa-cog-only-email')
    var bothTooltip = $(container).find('.fa-cog-both-templates')

    if ($.trim(email) != '' && $.trim(phone) != '') {
      bothTooltip.removeClass('hidden')
      emailTooltip.addClass('hidden')
      phoneTooltip.addClass('hidden')
    } else if ($.trim(email) != '' && $.trim(phone) == '') {
      emailTooltip.removeClass('hidden')
      phoneTooltip.addClass('hidden')
      bothTooltip.addClass('hidden')
    } else {
      phoneTooltip.removeClass('hidden')
      emailTooltip.addClass('hidden')
      bothTooltip.addClass('hidden')
    }
  }

  bind_resend_popover_preview_listener() {
    $('body').on('click', '.resend-popover-info', function(event) {
      var element = $(event.target)
      var new_title = element.data('title')
      var new_content = element.data('content')
      var popover = element.closest('.collaboration_invitation_container').find('.label-with-popover')
      popover.attr('data-original-title', new_title)
      popover.attr('data-content', new_content)
      popover.popover('show')
      popover.data('bs.popover').tip().addClass('collaboration-invitation-popover')
    })
  }

  bind_close_popover_preview_listener() {
    $('body').on('click', null, function(event) {
      var element = $(event.target)
      if (element.data('toggle') != 'popover' && element.parents('.popover.in').length == 0) {
        $('[data-toggle="popover"]').popover('hide');
      }
    })
  }


  bind_resend_message() {
    $(document).off('click', '.js-resend-message')
    $(document).on('click', '.js-resend-message', function(e) {
      var $target = $(e.currentTarget)

      if ($target.data('disabled')) {
        e.preventDefault()
        e.stopPropagation()
      } else {
        Passare.Utilities.Bootbox.confirm(I18n.t('family_and_friends.messages.resend_message_confirm'), function(result) {
          if (result) {
            var url = $target.data('url')

            $.ajax({
              url: url,
              dataType: 'json',
              method: 'PATCH',
              success: function (data) {
                Passare.Flash.success(data.message)
              },
              error: function(data) {
                Passare.Flash.error(data.responseJSON.errors)
              }
            })
          }
        })
      }
    })
  }

  sendPCInviteToggled() {
    this.validateForm()
    if ($("#caller-collapsible-section .js-send-pc-invite-checkbox").is(':checked')) {
      $('#caller-collapsible-section .pc-role-selector-container').find('input, select').passare_enable()
    } else {
      $('#caller-collapsible-section .pc-role-selector-container').find('input, select').passare_disable()
    }
  }

  validateForm() {
    const primaryPhone = this.hasPrimaryPhoneTarget ? this.primaryPhoneTarget.value : ''
    const email = this.hasEmailTarget ? this.emailTarget.value : ''

    if (!this.hasFirstNameTarget) return;

    if (this.firstNameTarget.value.length > 0 || this.lastNameTarget.value.length > 0 || this.relationshipTarget.value.length > 0 || primaryPhone.length > 0 || email.length > 0) {
      this.firstNameTarget.parentElement.classList.add(this.requiredClass)
      this.lastNameTarget.parentElement.classList.add(this.requiredClass)
      this.relationshipTarget.parentElement.classList.add(this.requiredClass)
    } else {
      this.firstNameTarget.parentElement.classList.remove(this.requiredClass)
      this.lastNameTarget.parentElement.classList.remove(this.requiredClass)
      this.relationshipTarget.parentElement.classList.remove(this.requiredClass)
    }

    requireFields(this.rolodexTargets, this.hasRolodexTarget && this.isMedicalOrLegalRelationship())

    if (this.visibleFieldsValue.includes('planning_center_invite')) {
      requireField(this.primaryPhoneTarget, this.isPlanningCenterInvite() && (email == '' || primaryPhone != ''))
      requireField(this.emailTarget, this.isPlanningCenterInvite() && (primaryPhone == '' || email != ''))

      this.planningCenterTokenFieldTargets.forEach(input => input.disabled = !this.isPlanningCenterInvite())
    }
  }

  syncPrimaryPhone() {
    // sync primary phone number in the Arrangement section if acquaintances are the same
    const caseCallerAcquaintanceId = $('#case_case_caller_attributes_acquaintance_attributes_id').val()
    const residenceOwnerId = $('.js-residence-owner').val()

    const phone_value = this.primaryPhoneTarget.value

    if (caseCallerAcquaintanceId === residenceOwnerId) {
      const $phone_input = $('.js-appointment-phone-numbers input[name*="[residence_owner_attributes][contact_information_attributes][phone_numbers_attributes][0][value]"]')
      $phone_input.val(phone_value)
    }
    $('.js-residence-owner option[value="' + caseCallerAcquaintanceId + '"]').attr('data-primary-phone-value', phone_value)
  }

  isFamilyRelationship() {
    return this.hasRelationshipTarget && this.familyRelationshipListValue.includes(this.relationshipTarget.value)
  }

  isMedicalOrLegalRelationship() {
    return this.hasRelationshipTarget && this.medicalRelationshipListValue.includes(this.relationshipTarget.value)
  }

  isPlanningCenterInvite() {
    return this.hasPlanningCenterInviteTarget && this.planningCenterInviteTarget.checked;
  }

  handleFieldsByRelationship() {
    ACQUAINTANCE_FAMILY_FIELDS_SELECTORS.forEach((selector) => {
      const fieldSection = this.element.querySelector(selector)
      this.isFamilyRelationship() ? visibilityHandler.show(fieldSection, false) : visibilityHandler.hide(fieldSection, false)
    })

    MEDICAL_FIELDS_SELECTORS.forEach((selector) => {
      const fieldSection = this.element.querySelector(selector)
      this.isMedicalOrLegalRelationship() ? visibilityHandler.show(fieldSection, false) : visibilityHandler.hide(fieldSection, false)
    })
  }
}
