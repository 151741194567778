import { Controller } from 'stimulus';

export default class extends Controller {
  static values = {
    url: String
  }

  connect() {
    this.bindFormEvents();
  }

  bindFormEvents() {
    const form = this.element.closest('form');

    form.addEventListener('change', (ev) => ev.target.name && this.fetchInvitationMessages(ev));
    form.addEventListener('click', (ev) => ev.target.closest('.js-load-invitation-message') && this.fetchInvitationMessages(ev));
  }

  fetchInvitationMessages(ev) {
    const form = this.element.closest('form');
    const fa_icon = form.querySelector('.js-load-invitation-message i.fa');

    if (fa_icon.matches(['.fa-caret-down', '.fa-spinner'])) return;
    fa_icon.classList.add('fa-spinner', 'fa-spin')

    $.ajax({
      url: this.urlValue,
      method: 'POST',
      dataType: 'JSON',
      data: $(form).serializeArray(),
      success: (response) => {
        this.element.querySelector('.email-template .title').innerHTML = 'Email template'
        this.element.querySelector('.email-template .content').innerHTML = response.email_template
        this.element.querySelector('.sms-template .title').innerHTML = 'Text template'
        this.element.querySelector('.sms-template .content').innerHTML = response.sms_template
      },
      error: (response) => {
        console.error('An error has ocurred while fetch template message preview', response)
      },
      complete: () => fa_icon.classList.remove('fa-spinner', 'fa-spin')
    })
  }
}
