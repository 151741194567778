import { Controller } from 'stimulus';
import visibilityHandler from '../shared/visibility_handler';
import { requireField } from '../shared/form_utils';

const PALLBEARER_ROLE_IDS = ['4', '5'];

export default class extends Controller {
  static targets = [
    'caller', 'relationship', 'role', 'firstName', 'lastName', 'mobilePhone', 'email', 'providerType',
    'planningCenterInvite', 'planningCenterRole'
  ]

  static values = {
    familyRelationshipList: Array,
    personEntryProviderTypes: Object
  }

  connect() {
    this.initializeRoleElement()
    this.handleRequiredFields()
  }

  initializeRoleElement() {
    $(this.roleTarget).SumoSelect({csvDispCount: 1})

    const sumoWrapper = this.roleTarget.parentElement.querySelector('.optWrapper')
    $(sumoWrapper).on('click', 'li', (ev) => {
      if (!this.planningCenterInviteTarget.checked) {
        this.planningCenterInviteTarget.checked = this.isPCInvitationRole()
        this.handleRequiredFields()
      }

      const roleId = ev?.currentTarget?.dataset?.val;
      if (PALLBEARER_ROLE_IDS.includes(roleId)) this.handlePallbearer(roleId);
    });
  }

  handleRequiredFields(ev) {
    // caller field checkbox
    if (this.hasCallerTarget) {
      requireField(this.callerTarget, this.anyContactValue() || this.isPlanningCenterInvite());
    }

    // acquaintance person / rolodex person fields
    const isNameAndRelationshipRequired = this.isCallerChecked() || this.isPlanningCenterInvite() || !this.hasCallerTarget && this.anyContactValue();
    requireField(this.firstNameTarget, isNameAndRelationshipRequired);
    requireField(this.lastNameTarget, isNameAndRelationshipRequired);
    requireField(this.relationshipTarget, isNameAndRelationshipRequired);

    // contact infomation
    requireField(this.mobilePhoneTarget, this.isPlanningCenterInvite() && (this.emailTarget.value == '' || this.mobilePhoneTarget.value != ''))
    requireField(this.emailTarget, this.isPlanningCenterInvite() && (this.mobilePhoneTarget.value == '' || this.emailTarget.value != ''))

    // roleTarget parent is a SumoSelect container
    this.roleTarget.disabled = this.isRolodexRelationship();
    this.roleTarget.parentElement.classList.toggle('disabled', this.isRolodexRelationship());
    if (this.isRolodexRelationship()) this.roleTarget.sumo.unSelectAll()

    // enable or disable hidden fields specific to rolodex entry
    const personEntryHiddenDataContainer = this.element.querySelector('.js-rolodex-person-entry');
    visibilityHandler.disableInnerFields(personEntryHiddenDataContainer);
    if (this.isRolodexRelationship()) visibilityHandler.enableInnerFields(personEntryHiddenDataContainer);

    this.handlePlanningCenterOptions();
  }

  handlePallbearer(roleId) {
    const currentRoleIndex = Array.prototype.map.call(this.roleTarget.selectedOptions, a => a.value).indexOf(roleId.toString())
    const otherRoleIndex = Array.prototype.map.call(this.roleTarget.options, a => a.value).indexOf((9 - roleId).toString())

    if (currentRoleIndex == -1 || otherRoleIndex == -1) return;
    this.roleTarget.sumo.unSelectItem(otherRoleIndex);
  }

  handleProviderTypes(ev) {
    this.providerTypeTarget.value = this.personEntryProviderTypesValue[this.relationshipTarget.value.toLowerCase()] || ''
  }

  handlePlanningCenterOptions() {
    const disablePlanningCenterFields = this.relationshipTarget.value != '' && this.isRolodexRelationship()
    if (disablePlanningCenterFields) this.planningCenterInviteTarget.checked = false
    this.planningCenterInviteTarget.disabled = disablePlanningCenterFields;

    const accessTokenContainer = this.element.querySelector('.js-access-token-container');
    this.isPlanningCenterInvite() ? visibilityHandler.enableInnerFields(accessTokenContainer) : visibilityHandler.disableInnerFields(accessTokenContainer);

    const inviteCheckboxContainer = this.planningCenterInviteTarget.closest('.planning-center-invite')
    const caretIconContainer = inviteCheckboxContainer.querySelector('.js-load-invitation-message')
    inviteCheckboxContainer.classList.toggle('disabled', disablePlanningCenterFields)
    caretIconContainer.classList.toggle('disabled', !this.isPlanningCenterInvite())

    if (!this.isPlanningCenterInvite()) caretIconContainer.dispatchEvent(new Event('collapse'));
  }

  anyContactValue() {
    const contactTargets = [
      this.relationshipTarget,
      this.roleTarget,
      this.firstNameTarget,
      this.lastNameTarget,
      this.mobilePhoneTarget,
      this.emailTarget
    ]

    return contactTargets.any(el => el.value != '')
  }

  isPlanningCenterInvite() {
    return this.planningCenterInviteTarget.checked;
  }

  isPCInvitationRole() {
    return Array.prototype.any.call(
      this.roleTarget.selectedOptions,
      (option) => option.getAttribute('data-planning-center-invitation') == 'true'
    );
  }

  isFamilyRelationship() {
    return this.familyRelationshipListValue.includes(this.relationshipTarget.value)
  }

  isRolodexRelationship() {
    return !this.isFamilyRelationship();
  }

  isCallerChecked() {
    if (!this.hasCallerTarget) return false;

    return this.callerTargets.any(el => el.checked);
  }
}
